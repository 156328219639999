import { ref } from 'vue';
import { defineStore } from 'pinia';
import { subDays, format, startOfDay, endOfDay } from 'date-fns';

// NOTE: there was a time when this constant was called "notquiteso"
//       which although funny is not the easiest to parse for the less Madgab inclined.
const NOT_QUITE_ISO = "yyyy-MM-dd'T'HH:mm:ss.SSS";

export default defineStore('timerange', () => {
	const today = new Date();

	const start = ref(subDays(today, 30));
	const end = ref(today);
	const qlpRange = ref(1);
	const adwordsRange = ref({
		text: 'Last 30 Days',
		value: {
			start: format(startOfDay(subDays(today, 30)), NOT_QUITE_ISO),
			end: format(endOfDay(today), NOT_QUITE_ISO),
		},
	});

	return {
		start,
		end,
		qlpRange,
		adwordsRange,
	};
});
