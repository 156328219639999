import { ref } from 'vue';
import { defineStore } from 'pinia';

import { useApi } from '@/composables/useApi';
import { parseISO, addDays } from 'date-fns';

export default defineStore('agent/payment', () => {
	const methods = ref(null);
	const info = ref(null);

	async function getMethods() {
		const result = await useApi('payment/', {
			agent: true,
			message: 'Oops, there was an issue with getting your payment info.',
		}).json();

		const newPaymentMethods = result?.data?.value?.objects;
		if (!newPaymentMethods) {
			this.$reset();
			return;
		}

		let noCard = false;
		let expiredCard = false;
		let expiringCard = false;

		const cards = newPaymentMethods.filter(cc => cc.card_type !== 'eighttrack');
		const defaultCard = cards.find(item => item.default === true);
		const eightTrackSelected = newPaymentMethods.some(cc => cc.card_type === 'eighttrack');
		const selectedCard = eightTrackSelected
			? { bin: 'Comp Deduct', use_eight_track: true, card_type: 'eighttrack' }
			: defaultCard;

		if (!eightTrackSelected) {
			if (defaultCard) {
				if (defaultCard.is_expired) {
					expiredCard = true;
				} else if (addDays(new Date(), 30) > parseISO(defaultCard.expires)) {
					expiringCard = true;
				}
			} else if (cards.length < 1) {
				noCard = true;
			}
		}

		methods.value = cards;
		info.value = {
			card: selectedCard,
			no_card: noCard,
			expired_card: expiredCard,
			expiring_card: expiringCard,
			good: !!selectedCard && !noCard && !expiredCard,
		};
	}

	async function ensureMethods() {
		if (!methods.value) {
			await getMethods();
		}
	}

	async function updateMethod(token, card) {
		const res = await useApi(`payment/${token}/`, {
			agent: true,
			message: `There was an issue updating your payment method. Please try again later.`,
		})
			.put({ card_data: card })
			.json();

		if (res?.data?.value?.error) {
			return Promise.reject(res.data.value.error);
		}

		return getMethods();
	}

	async function createMethod(card) {
		try {
			const res = await useApi(`payment/`, {
				agent: true,
				message: `There was an issue saving your new payment method. Please try again later.`,
			})
				.post({ card_data: card })
				.json();

			const { error } = res.data?.value ?? {};
			if (error) {
				throw error;
			}

			return getMethods();
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async function deleteMethod(token) {
		await useApi(`payment/${token}/`, {
			agent: true,
			message: `There was an issue deleting your payment method. Please try again later.`,
		}).delete();
		return getMethods();
	}

	async function selectMethod(token) {
		await useApi(`payment/${token}/`, {
			agent: true,
			message: 'There was an issue selecting your payment method. Please try again later.',
		}).put({ make_default: true });
		return getMethods();
	}

	async function useCompDeduct() {
		await useApi(`payment/payment_type/`, {
			agent: true,
			message: 'There was an issue trying to use comp deduct. Please try again later.',
		}).put({ use_eight_track: true });
		return getMethods();
	}

	return {
		methods,
		info,

		getMethods,
		ensureMethods,
		updateMethod,
		createMethod,
		deleteMethod,
		selectMethod,
		useCompDeduct,
	};
});
