import { ref } from 'vue';
import { defineStore } from 'pinia';
import { differenceInSeconds } from 'date-fns';

import useUserStore from '@/stores/user';
import { useApi } from '@/composables/useApi';

function parseQuerystring(query_string) {
	let qs_data = {};
	query_string.split('&').forEach(i => {
		const parts = i.split('=');
		qs_data[parts[0]] = parts[1];
	});
	return qs_data;
}

export default defineStore('auth', () => {
	const lastLoginAttempt = ref(null);

	const inLoginLoop = ref(false);

	function setLastLogin() {
		lastLoginAttempt.value = new Date();
	}

	async function login() {
		if (differenceInSeconds(new Date(), lastLoginAttempt.value) > 30) {
			inLoginLoop.value = true;
			return;
		}
		inLoginLoop.value = false;
		setLastLogin();

		const hash = window.location.hash.replace('#', '');
		const params = Object.fromEntries(new URLSearchParams(hash).entries());

		try {
			const res = await useApi(`authenticate/`, {
				message: 'There was an issue authenticating your login! Please try again later.',
			}).post({ ...params });

			return Promise.resolve(res.data?.value);
		} catch (error) {
			return await Promise.reject(error);
		}
	}

	async function logout() {
		await useApi('signout/', {
			message: 'There was an issue logging out of your account! Please try again later.',
		}).post();
		window.csrf_token = null;
		const userStore = useUserStore();
		userStore.$reset();
	}

	function checkForBackendRedirect() {
		// todo: figure out if this is still used
		// see if the backend wants to redirect
		// somewhere on the frontend after logging in
		// this can be removed when m1redirect
		// moves to the frontend.
		try {
			const qs = parseQuerystring(window.location.search.substring(1));

			if (qs.backend_redirect_url) {
				const redirect_path = decodeURIComponent(qs.backend_redirect_url);
				localStorage.setItem(
					'nextPath',
					`${window.location.protocol}//${window.location.host}/${redirect_path}`
				);
			}
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async function getLoginUrl() {
		try {
			const res = await useApi(`login_url/`, {
				message: 'There was an issue getting your login URL! Please try again later.',
			}).json();

			return Promise.resolve(res.data?.value?.login_url);
		} catch (error) {
			return Promise.reject(
				`HTTP Request failed with error status ${error.status}: ${error.text}`
			);
		}
	}

	return {
		checkForBackendRedirect,
		lastLoginAttempt,
		inLoginLoop,
		getLoginUrl,
		logout,
		login,
	};
});
