import { defineStore } from 'pinia';
import { useApi } from '@/composables/useApi';
import { ref } from 'vue';
import keywordList from '@/components/glossary/keywordList';

export const useGlossaryStore = defineStore('glossary', () => {
	const keywords = ref([]);
	const loadProgress = ref(0);
	const fetchingKeywords = ref(false);

	function getKeywordDetails(keyword) {
		return keywords.value?.find?.(({ path }) => path === keyword) ?? false;
	}

	async function getKeywordList() {
		try {
			loadProgress.value = 0;
			// const fetcher = useApi('api/v3/glossary/');
			// watchEffect(() => {
			// 	loadProgress.value = fetcher.progress;
			// });
			// const { data } = await fetcher.get().json();

			// keywords.value = data.value;
			// return Promise.resolve(data);

			// use hardcoded list until backend is finished
			keywords.value = [
				...Object.entries(keywordList)
					.map(([key, value]) => ({ ...value, path: key }))
					.sort((a, b) => a.title.localeCompare(b.title)),
			];
			loadProgress.value = 100;
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		}
	}
	async function ensureKeywords() {
		if (!(keywords.value?.length > 0)) {
			await getKeywordList();
		}
	}
	async function saveKeyword({ json }) {
		await useApi(`api/v3/glossary/`, {
			message: 'Saving that keyword failed.',
		}).post(json);
		await getKeywordList();
	}
	async function updateKeyword({ json, id }) {
		await useApi(`api/v3/glossary/${id}/`, {
			message: 'Saving that keyword failed.',
		}).put(json);
		await getKeywordList();
	}
	async function deleteKeyword({ id }) {
		await useApi(`api/v3/glossary/${id}/`).delete();
		await getKeywordList();
	}

	return {
		keywords,
		loadProgress,
		fetchingKeywords,

		ensureKeywords,
		updateKeyword,
		deleteKeyword,
		saveKeyword,

		getKeywordDetails,
	};
});
