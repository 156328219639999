import { defineStore } from 'pinia';
import { useApi } from '@/composables/useApi';
import { ref } from 'vue';

export default defineStore('notifications', () => {
	const notifications = ref(null);

	async function getSiteNotifications() {
		const result = await useApi('api/v3/site_notifications/', {
			message: 'there was an issue retrieving notifications! Please try again later.',
		}).json();

		notifications.value = result?.data;

		return Promise.resolve();
	}
	async function saveNotification({ json }) {
		await useApi('api/v3/site_notifications/', {
			message: `Saving that notification failed.`,
		}).post(json);
		await getSiteNotifications();
	}
	async function updateNotification({ json, id }) {
		await useApi(`api/v3/site_notifications/${id}/`, {
			message: `Saving that notification failed.`,
		}).put(json);

		await getSiteNotifications();
	}
	async function deleteNotification({ id }) {
		await useApi(`api/v3/site_notifications/${id}/`, {
			message: `Deleting that notification failed.`,
		}).delete();
		await getSiteNotifications();
	}

	return {
		notifications,

		getSiteNotifications,
		saveNotification,
		updateNotification,
		deleteNotification,
	};
});
