import { defineStore } from 'pinia';

import { useApi } from '@/composables/useApi';

import useAlertStore from '@/stores/alerts';
import { ref } from 'vue';

export default defineStore('overview', () => {
	const MXStats = ref(null);
	const MXStatsCanceler = ref(null);
	const approvalQueue = ref(null);
	const approvalQueueCount = ref(0);
	const qlpStats = ref(null);

	async function getParticipationByVPA({ vpaCode }) {
		try {
			const fetcher = useApi(`overview/api/participation/?vpa_code=${vpaCode}`, {
				message: 'There was an issue retrieving your VPA code! Please try again later.',
			});
			const response = await fetcher.json();
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	async function getParticipationByTerritory({ territoryCode }) {
		try {
			const fetcher = useApi(`overview/api/participation/?territory_code=${territoryCode}`, {
				message:
					'There was an issue retrieving your territory code! Please try again later',
			});
			const response = await fetcher.json();
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	async function getMXStats({ start, end }) {
		// cancel existing request, if any
		MXStatsCanceler.value?.();
		MXStats.value = null;

		try {
			const fetcher = useApi(
				`overview/api/m1stats/?${new URLSearchParams({
					start,
					end,
				})}`,
				{
					message: `There was an issue with retrieving those M1 Stats! Please try again later.`,
				}
			);
			MXStatsCanceler.value = fetcher.abort;
			const response = await fetcher.json();

			MXStats.value = response.data.value;
			MXStatsCanceler.value = undefined;
		} catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async function getCalltracking({ start, end }) {
		try {
			const fetcher = useApi(
				`overview/api/calltracking/?${new URLSearchParams({
					start,
					end,
				})}`,
				{
					message: `There was an issue retrieving that calltracking data.`,
				}
			);
			const response = await fetcher.json();
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	async function getApprovalQueue() {
		try {
			const fetcher = useApi(`overview/api/queue/`, {
				message: `There was an issue loading the approval queue.`,
			});
			const response = await fetcher.json();
			approvalQueue.value = response.data.value?.queue;
		} catch (error) {
			return Promise.reject(error);
		}
	}
	async function getApprovalQueueCount({ timestamp }) {
		try {
			const fetcher = useApi(
				`overview/api/queue/count/?${new URLSearchParams({
					timestamp,
				})}`,
				{
					message: `Something went wrong checking for new queue items.`,
				}
			);
			const response = await fetcher.json();
			approvalQueueCount.value = response.data.value?.count;
		} catch (error) {
			return Promise.reject(error);
		}
	}
	async function getQLPStats({ start, end }) {
		qlpStats.value = null;
		try {
			const fetcher = useApi(
				`overview/api/qlp/?${new URLSearchParams({
					start,
					end,
				})}`,
				{
					message: 'There was an issue retrieving QLP stats.',
				}
			);
			const response = await fetcher.json();
			//api response.data is currently an object with `access_denied`, `data`, and `success` properties
			qlpStats.value = response.data.value?.data;
		} catch (error) {
			const alertStore = useAlertStore();

			alertStore.addAlert({
				message: 'Failed to get the QLP stats!',
			});

			return Promise.reject(error);
		}
	}

	return {
		MXStats,
		MXStatsCanceler,
		approvalQueue,
		approvalQueueCount,
		qlpStats,

		getQLPStats,
		getApprovalQueue,
		getApprovalQueueCount,
		getCalltracking,
		getMXStats,
		getParticipationByTerritory,
		getParticipationByVPA,
	};
});
