import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { sub, format, startOfDay, endOfDay, isDate } from 'date-fns';

const DEFAULT_CHOICES = [
	{ text: '30 days', data: { days: '30' } },
	{ text: '60 days', data: { days: '60' } },
	{ text: '90 days', data: { days: '90' } },
	{ text: '6 months', data: { months: '6' } },
	{ text: '1 year', data: { years: '1' } },
];

function makeHumanReadableTitle(start, end) {
	return `${format(startOfDay(start), 'MMM do, yyyy')} - ${format(
		endOfDay(end),
		'MMM do, yyyy'
	)}`;
}

export default defineStore('time', () => {
	const start = ref(null);
	const end = ref(null);
	const label = ref(null);
	const range = ref(null);
	const customItem = ref(null);

	const choices = computed(() => {
		return customItem.value ? [customItem.value, ...DEFAULT_CHOICES] : DEFAULT_CHOICES;
	});

	function setCustomRange(startDate, endDate) {
		if (!isDate(startDate) || !isDate(endDate)) {
			throw 'Valid start and end dates required';
		}
		const rangeLabel = makeHumanReadableTitle(startDate, endDate);

		// make a custom item that the user can select later
		customItem.value = {
			text: rangeLabel,
			data: {
				custom_result: true,
				start: start,
				end: end,
				label: rangeLabel,
				range: rangeLabel,
			},
		};

		start.value = startDate;
		end.value = endDate;
		label.value = rangeLabel;
		range.value = rangeLabel;
	}

	function setChoice(choice) {
		if (choice.data.custom_result) {
			if (!isDate(choice.data.start) || !isDate(choice.data.end)) {
				throw 'Valid start and end dates required';
			}

			start.value = choice.data.start;
			end.value = choice.data.end;
			range.value = choice.data.range;
			label.value = choice.data.label;
		} else {
			const startDate = sub(endOfDay(new Date()), choice.data);
			const endDate = startOfDay(new Date());

			start.value = startDate;
			end.value = endDate;
			range.value = makeHumanReadableTitle(startDate, endDate);
			label.value = choice.text;
		}
	}

	return {
		start,
		end,
		label,
		range,
		customItem,
		choices,

		setCustomRange,
		setChoice,
	};
});
